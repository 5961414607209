* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: Roboto;

  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;

  font-size: 16px;
}

body {
  background-color: #202020;
  color: #e5e6e0;

  height: 100%;
}

.yellow {
  color: #ffc412;
}

::selection {
  color: #202020;
  background: #daa520;
}

.section-padding {
  padding: 6rem 158px;
}

@media screen and (max-width: 1024px) {
  .section-padding {
    padding: 6rem 52px 0;
    /* padding-top: 4rem; */
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .section-padding {
    padding: 6rem 32px 0;
  }
}
