h1 {
  font-family: 'Bungee';
  font-weight: 700;
  font-size: 5.5rem;
  line-height: 1.1em;

  text-align: center;

  margin-bottom: 0.1em;
}

::selection {
  line-height: 3.5rem;
}

span {
  color: gold;
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 3.75rem;
  }
}
