.about-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.about-container img {
  width: 50%;
}

.about-info-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-left: 4rem;
}

.about-info-container p {
  font-family: 'Roboto';
  font-size: 1rem;
  line-height: 1.5em;
}

.about-link-container {
  display: flex;
  align-items: center;
  gap: 1rem;

  margin-top: 1.5rem;
}

.about-link-container a {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2em;
  text-decoration: underline;

  color: #e5e6e0;

  transition: all ease-in-out 100ms;
}

.about-link-container a:first-child {
  color: #323232;
  background-color: #ffc412;

  text-decoration: none;
  border-radius: 50px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);

  padding: 0.4em 1em;
}

.about-link-container a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 640px) {
  .about-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .about-container img {
    width: 100%;
  }

  .about-info-container {
    margin: 0;
    width: 100%;
    gap: 0.5rem;
  }
}
