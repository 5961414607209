.projects-menu ul {
  display: flex;
  flex-direction: row;

  list-style: none;
  gap: 1rem;

  padding: 0.5rem 0;
}

.projects-menu li {
  width: 100%;
}

.projects-menu li:last-child {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;

  background: var(--gray-600, #323232);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.5em;
  cursor: pointer;
}

.projects-menu p {
  padding: 0.3em 1.5em;
  width: 100%;
}

.projects-menu input {
  color: #e5e6e0;

  font-size: 1rem;
  background: none;
  border: none;

  width: 100%;
  min-width: 200px;
  padding: 0.2em 1em;
  outline: none;
}

.projects-menu input::placeholder {
  color: #e5e6e0b4;
}

.projects-menu svg {
  font-size: 1.3rem;
  text-align: center;
  margin: 0 0.5em;
}

.projects-menu svg:hover {
  opacity: 0.7;
}

.projects-container {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  gap: 4rem;
}

.project:nth-child(even) {
  flex-direction: row-reverse;
}

.project {
  background-color: #32323223;

  border: 2px solid #32323288;
  border-radius: 16px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);
  
  padding: 1rem;
  display: flex;
  flex-direction: row;
  gap: 65px;
}

.project-banner {
  position: relative;
  width: 60%;
  height: 100%;
}

.project-cover {
  border-radius: 0.625rem;
  background: var(--gray-600, #323232);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);

  max-width: 100%;
  height: 300px;

  object-fit: cover;
}

.project-icon {
  width: 4rem;
  height: 4rem;
  position: absolute;
  right: 0;
  bottom: 0;

  margin: 24px;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  gap: 1rem;

  margin: 0.5rem 0;
}

.project-info h3 {
  margin: 0;
}

.project-info .project-links {
  display: flex;
  gap: 1rem;

  margin-top: auto;
}


@media screen and (max-width: 640px) {
  .projects-menu {
    scrollbar-width: none;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }

  .projects-menu:-webkit-scrollbar {
    display: none;
  }

  .projects-container {
    margin-top: 2rem;
  }

  .project {
    flex-direction: column;
    gap: 1rem;
  }

  .project h3 {
    font-size: 2rem;
  }

  .project:nth-child(even) {
    flex-direction: column;
  }

  .project-banner,
  .project-cover {
    width: 100%;
    max-height: 200px;
  }

  .project-info {
    width: 100%;
  }
}
