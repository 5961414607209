h2 {
  font-family: 'Sarabun';
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.1em;

  margin-bottom: 1.5rem;
}

span {
  color: gold;
  font-family: 'Sarabun';
}

@media screen and (max-width: 640px) {
  h2 {
    font-size: 2rem;
  }
}
