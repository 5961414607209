
.pagelinks-main-container {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 450px;
  height: 100dvh;

  gap: 1.5rem;

  margin: 0 auto;
}

.pagelinks-heading-container {
  display: grid;
  gap: 1rem;

  grid-template-columns: 1fr 2fr;
}

.pagelinks-heading-container img {
  border-radius: 20px;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.pagelinks-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  align-items: start;
  margin-left: 0.5em;
}
.pagelinks-title-container h1 {
  font-size: 4em !important;
}

.pagelinks-title-container p {
  font-size: 0.9rem;
  line-height: 16px;
  text-align: center;
  cursor: text;

  color: #000000;
}

.pagelinks-title-container p:hover {
  opacity: 1;
}

.pagelinks-links-container {
  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 16px;
}

.pagelinks-links-container a.button {
  color: #a1a1a1;
  background-color: #323232;
  border-radius: 8px;
  font-weight: 400;
  
  cursor: pointer;
  
  padding: 4px 8px;
}

.pagelinks-main-container button {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  background: #323232;
  border: none;
  border-radius: .5em;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  line-height: 23px;
  cursor: pointer;

  height: 60px;
  width: 100%;

  transition: ease-in-out 100ms;
}

.pagelinks-main-container button:hover {
  opacity: 0.7;
  transform: scale(1.07);
}

.pagelinks-main-container button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.pagelinks-socialLinks-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto ;
  gap: 1em;
}

.pagelinks-socialLinks-container a {
  display: flex;
}

.pagelinks-socialLinks-container img {
  width: 100%;
  transition: transform ease-in-out 100ms;
}

.pagelinks-socialLinks-container img:hover {
  transform: scale(1.07);
}

.hide {
  display: none;
}

@media screen and (max-width: 480px) {
  .pagelinks-main-container {
    width: 212px;
  }

  .pagelinks-heading-container {
    display: grid;
    gap: 1rem;
    
    grid-template-rows: auto auto;
    grid-template-columns: none;
  
  }

  .pagelinks-title-container {
    align-items: center;
    margin: 2rem 0 1rem;
  }

  .pagelinks-title-container h1 {
    font-size: 2.5em !important;
  }

  .pagelinks-socialLinks-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.MuiAlert-action button  {
  color: unset;
  background: none ;
  height: 30px;
  box-shadow: none;
}