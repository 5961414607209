.services-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.services-cards-container {
  display: grid;
  align-items: center;
  flex-wrap: wrap;

  gap: 1.5rem;
  margin-bottom: 1.5rem;

  grid-template-columns: repeat(auto-fit, minmax(min(100%, 150px), 1fr));
}
