.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: row;

  background-color: #323232;
  border-radius: 24px;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.25);

  /* width: 100%; */
  padding: 2rem 3rem;
  gap: 4rem;
}

.footer-container * {
  margin-bottom: 0;
}

.footer-container p {
  font-size: 1rem;
}

@media screen and (max-width: 480px) {
  .footer {
    margin-bottom: 32px;
  }

  .footer-container {
    border-radius: 10px;

    padding: 1rem 1.5rem;
    gap: 0.5rem;
  }

  .footer-container h2 {
    font-size: 1.2rem;
  }

  .footer-container p {
    font-size: 1rem;
  }
}
