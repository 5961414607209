.navbar-container {
  z-index: 2;
  position: fixed;

  background: linear-gradient(
    180deg,
    rgba(32, 32, 32, 0.9612219887955182) 7%,
    rgba(32, 32, 32, 0.7343312324929971) 35%,
    rgba(32, 32, 32, 0.48503151260504207) 68%,
    rgba(32, 32, 32, 0) 100%
  );

  width: 100%;
  padding: 1rem 2rem;
}

.navbar-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.navbar-links-container {
  align-items: center;
  display: flex;
  gap: 1.5rem;
}

.navbar-links-container p {
  transition: all ease 100ms;
}

.navbar-links-container p:hover {
  opacity: 0.7;
}

.navbar-links-container a {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2em;
  cursor: pointer;

  color: #e5e6e0;
}

.navbar-menu {
  display: none;
}

@media screen and (max-width: 830px) {
  .navbar-links-container {
    display: none;
  }

  .navbar-menu {
    display: initial;
    position: relative;
  }

  .navbar-menu-container {
    position: absolute;
    right: 0;

    background-color: #323232;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);

    border-radius: 8px;

    padding: 1rem;
  }

  .navbar-menu-container-links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .navbar-menu-container-links p:last-child a {
    color: #323232;
  }

  .navbar-menu-container-links a {
    color: #e5e6e0;
  }
}
