.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  background-color: #323232;
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;
}

.tag-list button {
  background: none;
  border: none;
  cursor: pointer;
}

.tag-list img {
  height: 3.5rem;
}

.scroller {
  max-width: 100%;
  /* overflow: hidden; */
}

.scroller[data-animated="true"] {
  overflow: hidden;

  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 10%,
    white 90%,
    transparent
  );

  mask: linear-gradient(90deg, transparent, white 10%, white 90%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  flex-wrap: nowrap;
  animation: scroll 40s linear infinite;

  width: fit-content;
}

.scroller__inner {
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;
  padding-block: 2rem;
}

.PopoverContent {
  outline: none;
  max-width: 300px;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  background: var(--grey, #323232);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.PopoverContent h3 {
  margin-bottom: 0.75rem;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
